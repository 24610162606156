import React from 'react';

import { Spinner, SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { MediaCover } from '../../../../../components-shared/MediaCover';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { StepViewHidden } from '../StepViewHidden';
import { Challenges } from '../../../../../editor/types/Experiments';
import {
  getSectionDetails,
  getSectionMedia,
  getSectionTitle,
  getWaitingStart,
  isSectionLocked,
} from '../../../../../selectors/sections';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import { StepViewBreadcrumbs } from '../StepViewBreadcrumbs';
import { Ricos } from '../../../../../components-shared/Ricos/loadable/Ricos';
import { FCWithChildren } from '@wix/challenges-web-library';
import { classes, st } from './SidebarSectionView.st.css';

export interface ISidebarSectionView {
  currentSectionId: string;
  goToCurrentStep(): void;
  pageRef: React.RefObject<HTMLElement>;
  titleRef?: React.RefObject<HTMLElement>;
  goToMobileList(): void;
}

export const SidebarSectionView: FCWithChildren<ISidebarSectionView> = (
  props,
) => {
  const {
    listParticipantSections,
    isListParticipantSectionsRequestInProgress,
    requestParticipantSection,
  } = useSections();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const section = listParticipantSections.find(
    (s) => s.id === props.currentSectionId,
  );
  const isLocked =
    experiments.enabled(Challenges.enableDripContent) &&
    isSectionLocked(section);
  const source = section?.source;
  const details = source && getSectionDetails(source);
  const isDelayedDetails = details === undefined; // only `undefined` is suitable here

  React.useEffect(() => {
    if (isDelayedDetails && !isLocked) {
      void requestParticipantSection?.(
        props.currentSectionId,
        listParticipantSections,
      );
    }
  }, [props.currentSectionId]);

  if (!section) {
    return null;
  }

  const title = getSectionTitle(source);
  const media = getSectionMedia(source);

  if (isLocked) {
    return (
      <StepViewHidden
        isWeekFixForCTA={false}
        date={getWaitingStart(section)}
        titleKey="live.section.section-locked.title"
        goToCurrentStep={props.goToCurrentStep}
        descriptionKey="live.section.section-locked.description"
      />
    );
  }

  return isListParticipantSectionsRequestInProgress ? (
    <Spinner type={SpinnerTypes.slim} isCentered={false} />
  ) : (
    <div
      id={props.currentSectionId}
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      {isMobile && (
        <StepViewBreadcrumbs goToMobileList={props.goToMobileList} />
      )}
      <Fullscreen pageRef={props.pageRef} />

      <h3
        className={classes.stepViewTitle}
        dir="auto"
        tabIndex={-1}
        ref={props.titleRef as React.RefObject<HTMLHeadingElement>}
      >
        {title}
      </h3>

      <MediaCover
        fit="contain"
        media={media}
        className={classes.sectionMedia}
      />

      {isDelayedDetails ? (
        <Spinner
          type={SpinnerTypes.slim}
          isCentered={!isMobile && !media}
          className={media ? classes.spinnerWithContent : classes.spinner}
        />
      ) : (
        <div className={classes.sectionDescription}>
          <Ricos
            key={section?.id}
            details={details}
            context="section_description"
            contentId={section?.id}
            dataHook="section-description"
          />
        </div>
      )}
    </div>
  );
};
